<template>
	<div class="flex column v-margin30">
		<v-icon v-on:click="getInfos()">mdi-sync</v-icon>


		<div class="flex column m-auto align-items fit-content">
			<div class="flex row margin10 gap10 fit-content">

				<label style="font-size: 18px;">Limite skip time giornaliero:</label>

				<label style="font-size: 18px;">
					<p>{{ formattedSkippableTime }}</p>
				</label>


			</div>

			<div class="flex row margin10 align-items gap20">
				<div class="flex column fit-content">
					<label style="font-size: 18px;">Ore</label>
					<input type="checkbox" v-model="hours" v-on:change="switchMeasure('H')">
				</div>
				<div class="flex column fit-content">
					<label style="font-size: 18px;">Giorni</label>
					<input type="checkbox" v-model="days" v-on:change="switchMeasure('D')">
				</div>
			</div>
			<div class="flex row margin10 align-items gap20">
				<input v-model="orco" type="number" style="width: 100px; margin: 10px;" :placeholder="skippableTime">
				<v-btn style="width: fit-content;" color="bisque" v-on:click="updateLimit()">Conferma</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import store from '@/store';
export default {
	name: 'SettingsView',
	data: () => ({
		orco: null,
		hours: false,
		days: true
	}),
	computed: {
		skippableTime() {
			return store.getters.skeepLimit;
		},
		formattedSkippableTime() {
			if (store.getters.skeepLimit < 86400)
				return store.getters.skeepLimit / 60 / 60 + ' ore' ;
			return store.getters.skeepLimit / 60 / 60 / 24 + ' giorni';
		}
	},
	methods: {
		getInfos() {
			//store.dispatch('fetchAraInfos');
		},
		updateLimit() {
			if (this.orco == null || this.orco == undefined || this.orco.lenght == 0) return;
			let res = this.orco * (this.days ? 86400 : 3600);
			store.dispatch('updateSkeepLimit', { limit: res });
		},
		switchMeasure(tag) {
			this.days = tag === 'D';
			this.hours = tag === 'H';
		}
	}
}
</script>