<template>
  <v-app>
    <AppBar v-if="logged && index != 0" />
    <v-main style="padding-top: 50px;">
      <access-page v-if="index === '0'"></access-page>
      <products-view v-if="index === '1'"></products-view>
      <packs-view v-if="index === '2'"></packs-view>
      <rewards-view v-if="index === '3'"></rewards-view>
      <events-view v-if="index === '4'"></events-view>
      <quest-categories-view v-if="index === '5'"></quest-categories-view>
      <quests-view v-if="index === '6'"></quests-view>
      <code-view v-if="index === '7'"></code-view>
      <QuestionsView v-if="index === '8'"></QuestionsView>
      <BirdsView v-if="index === '9'"></BirdsView>
      <FortuneWheel v-if="index === '10'"></FortuneWheel>
      <DailyBird v-if="index === '11'"></DailyBird>
      <EmailsView v-if="index === '12'"></EmailsView>
      <AdsPage v-if="index === '13'"></AdsPage>
      <StatsPage v-if="index === '14'"></StatsPage>
      <ItemView v-if="index === '15'"></ItemView>
      <HomePage v-if="index === '16'"></HomePage>
      <SettingsView v-if="index === '17'"></SettingsView>

      <FornituresView v-if="index === '22'"></FornituresView>
      <DocumentationView v-if="index === '23'"></DocumentationView>
      <!-- <v-dialog persistent v-model="tutorial" transition="dialog-top-transition" max-width="600">
        <v-card style="text-align: flex-start; padding: 10px;">
          <h1>
            MEGA UPDATE
          </h1>
          <strong>
            <p>Aggiornamenti UX</p>
          </strong>
          <ul style="text-align: start;">
            <li>Ora l'appbar ovunque la si clicchi apre la sidebar</li>
            <li>Navigazione rapida appbar</li>
            <li>Aggiunta pagina valori degli oggetti in-game</li>
            <li>Apri la legenda dal punto di domanda e trascinala dove vuoi sullo schermo</li>
          </ul>
          <strong>
            <p style="text-align: end;">Cortesemente, 2F staff.</p>
          </strong>
          <v-card-actions class="justify-end">
            <button v-on:click="tutorial = false">CHIUDI</button>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-main>
    <div class="text-center ma-2">
      <v-snackbar color="success" v-if="response" :value="snackbar">
        <strong>{{ snackbar_text }}</strong>
      </v-snackbar>
      <v-snackbar color="error" v-if="!response" :value="snackbar">
        <strong>{{ snackbar_text }}</strong>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeSnackbar">
            Chiudi
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';
import store from '@/store/index.js'
import { ping } from './functions.js'
import AccessPage from './views/AccessPage.vue';
import ProductsView from './views/Product/ProductsView.vue';
import PacksView from './views/Pack/PacksView.vue';
import RewardsView from './views/Reward/RewardsView.vue';
import EventsView from './views/Event/EventsView.vue';
import QuestCategoriesView from './views/QuestCategory/QuestCategoriesView.vue';
import QuestsView from './views/Quest/QuestsView.vue';
import CodeView from './views/CodeView.vue';
import QuestionsView from './views/QuestionsView.vue';
import BirdsView from './views/BirdsView.vue';
import DailyBird from './views/DailyBird.vue';
import FortuneWheel from './views/FortuneWheel/FortuneWheel.vue';
import EmailsView from './views/EmailsView.vue';
import AdsPage from './views/Advertising/AdsPage.vue';
import StatsPage from './views/Statistiche/StatsPage.vue';
import ItemView from './views/Items/ItemView.vue';
import HomePage from './views/Home/HomePage.vue';
import SettingsView from './views/Ara/SettingsView.vue';
import FornituresView from './views/Ara/Forniture/FornituresView.vue';
import DocumentationView from './views/Ara/DocumentationView.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { AppBar, AccessPage, FortuneWheel, ProductsView, PacksView, RewardsView, EventsView, DailyBird, QuestCategoriesView, QuestsView, CodeView, QuestionsView, BirdsView, EmailsView, AdsPage, StatsPage, ItemView, HomePage, SettingsView, FornituresView, DocumentationView },
  name: 'App',
  data() {
    return {
      tutorial: false
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    closeSnackbar() {
      this.setSnackbar(false);
    }
  },
  watch: {
    snackbar(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.closeSnackbar();
        }, 2500);
      }
    }
  },
  computed: {
    ...mapGetters(['snackbar']),
    token() {
      return store.getters.token;
    },
    logged() {
      return store.getters.logged;
    },
    index() {
      return store.getters.index;
    },
    snackbar_text() {
      return store.getters.snackbarText;
    },
    response() {
      return store.getters.response;
    },
  },
  async mounted() {
    if (this.token !== null && this.token.length > 0) {
      var authorized = await ping();

      if (authorized) {
        store.dispatch('setLogged', true);

        if (store.getters.role !== 'bot') {
          store.dispatch('getProductRequest');
          store.dispatch('getPackRequest');
          store.dispatch('getEventRequest');
          store.dispatch('getQuestCategoryRequest');
          store.dispatch('getQuestRequest');
          store.dispatch('getRewardRequest');
          store.dispatch('getItemRequest');
          //store.dispatch('fetchAraInfos');
          store.dispatch('setIndex', this.index);
        } else {
          store.dispatch('setIndex', 16);
        }
      }
    }

    // var tut = localStorage.getItem('birder_tutorial');
    // if (tut == undefined || tut == null || !tut) {
    //   this.tutorial = true;
    //   localStorage.setItem('birder_tutorial', true)
    // }
    // localStorage.removeItem('birder_tutorial');
  },
};
</script>

<style>
@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url('./assets/fonts/Fredoka/Fredoka-VariableFont_wdth,wght.ttf');
}

@font-face {
  font-family: 'Cairo';
  src: local('Cairo'), url('./assets/fonts/Cairo/Cairo-VariableFont_slnt,wght.ttf');
}

:root {
  --primary-color: #283618;
  /* Example primary color */
  --secondary-color: #606C38;
  /* Example secondary color */
}

.theme--light.v-icon {
  color: bisque;
}

.theme--light.v-icon.special-icon {
  color: dimgray;
}

#app {
  margin: auto;
  background: var(--primary-color);
  text-align: center;
}

html {
  background: var(--primary-color);
}

.v-card {
  z-index: 0
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.align-items {
  align-items: center;
}

.fit-content {
  height: fit-content;
  width: fit-content;
}

.fit-width {
  width: fit-content;
}

.min-width {
  width: min-content;
}


.fit-heigth {
  height: fit-content;
}

.center-row {
  align-items: center;
}

.center-column {
  justify-content: center;
}

.m-auto {
  margin: 0 auto;
}

.margin5 {
  margin: 5px;
}

.r-margin5 {
  margin-right: 5px;
}

.l-margin5 {
  margin-left: 5px;
}

.b-margin5 {
  margin-bottom: 5px;
}

.t-margin5 {
  margin-top: 5px;
}

.margin10 {
  margin: 10px;
}

.r-margin10 {
  margin-right: 10px;
}

.l-margin10 {
  margin-left: 10px;
}

.b-margin10 {
  margin-bottom: 10px;
}

.t-margin10 {
  margin-top: 10px;
}

.margin20 {
  margin: 20px;
}

.r-margin20 {
  margin-right: 20px;
}

.l-margin20 {
  margin-left: 20px;
}

.b-margin20 {
  margin-bottom: 20px;
}

.t-margin20 {
  margin-top: 20px;
}

.margin30 {
  margin: 30px;
}

.r-margin30 {
  margin-right: 30px;
}

.l-margin30 {
  margin-left: 30px;
}

.b-margin30 {
  margin-bottom: 30px;
}

.t-margin30 {
  margin-top: 30px;
}

.v-margin5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.v-margin10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.v-margin20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.v-margin30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.h-margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.h-margin5 {
  margin-left: 5px;
  margin-right: 5px;
}

.h-margin10 {
  margin-left: 10px;
  margin-right: 10px;
}

.h-margin20 {
  margin-left: 20px;
  margin-right: 20px;
}

.h-margin30 {
  margin-left: 30px;
  margin-right: 30px;
}

.text-left {
  text-align: left;
}

.maxH30 {
  max-height: 30px;
}

.maxH50 {
  max-height: 50px;
}

.maxH70 {
  max-height: 70px;
}

.minH30 {
  min-height: 30px;
}

.minH50 {
  min-height: 50px;
}

.minH70 {
  min-height: 70px;
}

.maxW30 {
  max-width: 30px;
}

.maxW50 {
  max-width: 50px;
}

.maxW100 {
  max-width: 100px;
}

.maxW70 {
  max-width: 70px;
}

.minW30 {
  min-width: 30px;
}

.minW50 {
  min-width: 50px;
}

.minW70 {
  min-width: 70px;
}

.minW200 {
  min-width: 200px;
}

.minW300 {
  min-width: 300px;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.v-select {
  position: relative;
  height: fit-content;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

.gap40 {
  gap: 40px;
}

.align-start {
  text-align: flex-start;
}

.clear-margin {
  margin: 0 !important;
}


.center {
  margin: auto;
}

.relative {
  position: relative;
}

.H-max-content {
  height: 100%;
}

.checkbox {
  margin: auto;
  height: 27px;
  width: 27px;
}

.update-top-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  margin: auto;
  padding: 20px auto;
}

.options-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.options-btn {
  width: 150px;
  height: 50px;
  background-color: rgb(215, 250, 217);
  border-radius: 20px;
  border: 2px solid black;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.padding10 {
  padding: 10px;
}




.quest-body {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 20px auto;
  gap: 10px;
}

.quest-body h1 {
  font-family: 'Cairo';
  font-weight: 900;
  color: #ffe4c4;
  text-align: flex-start;
  height: fit-content;
}

.quest-body input {
  min-height: 30px;
  margin: 0 auto;
}

.event-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px auto;
  gap: 10px;
}

.event-body h1 {
  font-family: 'Cairo';
  font-weight: 900;
  color: #ffe4c4;
  text-align: flex-start;
  height: fit-content;
}

.event-body input {
  min-height: 30px;
  margin: 0 auto;
}

#sider {
  position: fixed;
  top: 5%;
  height: 90%;
  right: 10px;
  padding: 0px 15px;
  overflow-y: scroll;
}
</style>
